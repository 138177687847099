import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Navbar, Footer } from "views";
import InspectionDetails from "views/Sections/InspectionDetails";
import { AlternativeNavigation, SEO } from "components";

import "utils/fixFontAwesome";
import "../style/main.scss";

export const query = graphql`
  query inspectionQuery($langKey: String!) {
    site {
      meta: siteMetadata {
        keywords
        description
      }
    }
    navbar: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey }, fileName: { regex: "/navbar\\./i" } } }
    ) {
      nodes {
        i18n: frontmatter {
          ...NavbarMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    inspection: allMarkdownRemark(
      filter: {
        fields: {
          langKey: { eq: $langKey }
          fileName: { regex: "/inspection\\./i" }
          directoryName: { regex: "/pages/i" }
        }
      }
    ) {
      nodes {
        i18n: frontmatter {
          ...InspectionMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    footer: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey }, fileName: { regex: "/footer\\./i" } } }
    ) {
      nodes {
        i18n: frontmatter {
          ...FooterMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    sitemap: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey }, fileName: { regex: "/sitemap\\./i" } } }
    ) {
      nodes {
        i18n: frontmatter {
          ...LandingsNavigationMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    contact: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey }, fileName: { regex: "/contactUs\\./i" } } }
    ) {
      nodes {
        i18n: frontmatter {
          ...ContactUsMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const Terms = ({ data, pageContext }) => {
  const {
    site: {
      meta: { keywords, description },
    },
  } = data;

  const navBarNode = data.navbar.nodes[0];
  const footerNode = data.footer.nodes[0];
  const inspectionNode = data.inspection.nodes[0];
  const sitemapNode = data.sitemap.nodes[0];
  const contactUsNode = data.contact.nodes[0];
  const seo = { lang: pageContext.langKey, keywords, description };

  return (
    <div>
      <SEO title={inspectionNode.i18n.title} {...seo} />
      <Navbar frontmatter={navBarNode.i18n} langKey={pageContext.langKey}>
        <AlternativeNavigation context={pageContext} frontmatter={sitemapNode.i18n} />
      </Navbar>
      <InspectionDetails frontmatter={inspectionNode.i18n} />
      <Footer
        frontmatter={footerNode.i18n}
        navigationNode={sitemapNode.i18n}
        contactUsNode={contactUsNode.i18n}
      />
    </div>
  );
};

Terms.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

Terms.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default Terms;
